@use '@carbon/react/scss/colors';

#logout-link {
  margin-left: auto; // This will push the logout link to the end if inside a flex container
  width: 100px;
}

#login-link {
  width: 80px;
  margin-right: 10px;
}

// Username text styling
.username-text {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: #ffffff; // Ensure text is visible on dark header
  white-space: nowrap; // Prevent text wrapping
}

// Adjust spacing between logout link and username
#logout-link {
  margin-right: 0.5rem; // Add space between logout and username
}

// Adjust the user avatar action to align properly
.user-avatar-action {
  display: flex;
  align-items: center;
}

// Ensure the overflow menu icon (avatar) is visible and aligned
.username-overflow-menu .cds--overflow-menu__icon {
  color: #ffffff;
  display: flex;
  align-items: center;
}

// Optional: Adjust the overflow menu styles
.username-overflow-menu .cds--overflow-menu-options {
  background-color: #ffffff; // Menu background color
}

.username-overflow-menu .cds--overflow-menu-options__option {
  padding: 1rem; // Adjust menu item padding
}

// Overriding header tooltip background color
.cds--header__global .cds--popover-content {
  background-color: colors.$gray-20;
}

.cds--header__global .cds--popover-caret {
  background-color: colors.$gray-20;
}

// _ff-header.scss

.always-visible-menu-button {
  display: block !important;

  // Add these styles to fix the alignment
  margin-left: 1rem; // Adjust as needed
  margin-right: 1rem; // Ensure spacing between the menu button and header name
  order: 0; // Ensure the button appears at the correct position in the flex layout
}

.cds--header__menu-trigger {
  // Reset any negative margins or positioning applied by Carbon on larger screens
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 1056px) {
  .cds--header__menu-trigger {
    // Adjust the alignment specifically for larger screens
    margin-left: 1rem;
  }
}

// _ff-header.scss

.always-visible-menu-button {
  display: block !important;
  margin-left: 1rem;
  margin-right: 1rem;
  align-self: center;

  // Target the icon inside the button to ensure it's centered
  svg {
    display: block;
    margin: 0 auto;
  }
}

// Adjust the alignment when the SideNav is expanded
.cds--header__action--active.always-visible-menu-button {
  margin-left: 1rem;
  margin-right: 1rem;
  align-self: center;

  svg {
    display: block;
    margin: 0 auto;
  }
}

.cds--header__nav {
  display: flex;
  align-items: center;
}

.cds--header__name {
  margin-left: 0.5rem; // Adjust as needed for spacing
}

.cds--header__global {
  margin-left: auto; // Push global actions to the right
}
