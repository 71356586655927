@use '@carbon/react/scss/colors';

.billing-page {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.billing-container {
  min-width: 300px;
  max-width: 600px;
}

.form-item {
  margin-bottom: 1rem;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.button-group > .bx--btn {
  flex: 1 1 auto;
}
