@use '@carbon/react/scss/colors';

.email-integration-page {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.email-integration-container {
  min-width: 320px;
  max-width: 720px;
  width: 100%;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  h1 {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }

    input {
      width: 100%;
      padding: 0.75rem;
      border-radius: 4px;
      font-size: 1rem;

      &:focus {
        outline: none;
      }
    }
  }

  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;

    label {
      font-size: 0.875rem;
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;

    > .bx--btn {
      flex: 1 1 auto;
    }
  }

  .error-message {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
}
