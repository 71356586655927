@use '@carbon/react/scss/colors';

.create-account-page {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem; // Top/Bottom: 2rem, Left/Right: 1rem
}

.create-account-container {
  // Keep our responsive sizing
  min-width: 300px;
  max-width: 800px;
  width: 100%;

  // Add horizontal padding to prevent fields from hitting screen edges
  margin: 0 auto; // center the container
  padding: 0 2rem; // left/right padding for breathing room
}

.create-account-container h1,
.create-account-container h2 {
  margin-bottom: 1rem;
}

.create-account-container p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.create-account-container a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.button-group > .bx--btn {
  flex: 1 1 auto;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
}
