@use '@carbon/react/scss/colors';

.settings-page {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.settings-container {
  min-width: 700px;
  max-width: 800px;
}

.form-item {
  margin-bottom: 1rem;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; 
  margin-top: 1rem;
}

.button-group > .bx--btn {
  flex: 1 1 auto;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
}
