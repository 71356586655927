@use '@carbon/react/scss/colors';

.privacy-page {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.privacy-container {
  min-width: 300px;
  max-width: 800px;
  width: 100%;
}

.privacy-container h1,
.privacy-container h2 {
  margin-bottom: 1rem;
}

.privacy-container p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.privacy-container a {

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.button-group > .bx--btn {
  flex: 1 1 auto;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
}
