@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
@use '@carbon/themes/scss/compat/generated/tokens' as *;
@use '@carbon/react/scss/colors' as *;
@use './mixins.scss' as *;
@use './overrides.scss';

// Logo styles
.login-page__logo {
  // Hide logo on md and smaller
  @include breakpoint('md') {
    display: none;
  }
  // Show and center logo on lg
  @include breakpoint('lg') {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.login-page__logo img {
  width: 100%;      // scales image relative to parent
  height: auto;     // preserves aspect ratio
  display: block;   // removes extra space below inline images
  margin: 0 auto;   // centers the image horizontally
}

// Other page elements
.login-page__illo {
  max-width: 100%;
}

div.Centerdiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.Leftdiv {
  width: 300px;
  margin: 20px;
}

.login-page__banner {
  padding-top: $spacing-07;
  padding-bottom: $spacing-07;
  padding-left: $spacing-06;
  @include login-page-background;
}

.login-page__heading {
  @include type-style('productive-heading-05');
}

.login-page__r2 {
  margin-top: rem(-40px);
}

.tabs-group-content {
  padding: $spacing-10 $spacing-06;
}

.login-page__subheading {
  @include type-style('productive-heading-03');
  font-weight: 600;
}

.login-page-content {
  margin-top: 20px;
}

.login-page__p {
  @include type-style('productive-heading-03');
  margin-top: $spacing-06;
  margin-bottom: $spacing-08;
  
  @include breakpoint-between((320px + 1), md) {
    max-width: 75%;
  }
}

.login-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  padding-left: $spacing-06;
  @include login-page-background;
}

.login-page__label {
  @include type-style('heading-01');
}

.tabs-group {
  background-color: $layer-01;
}

// Adjust margins for banner and regions on larger screens
.login-page__banner,
.login-page__r2,
.login-page__r3 {
  margin-left: -20px;
  margin-right: -20px;
  @include breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
  }
}

// Login form styles
.login-form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
}

.login-input {
  margin-bottom: $spacing-05;
}

$interactive-01: #0053ff; // Example color – adjust as needed

.login-button {
  margin-bottom: $spacing-07;
  background-color: $interactive-01;
  color: $text-04;
  &:hover {
    background-color: darken($interactive-01, 10%);
  }
}

.social-style-login-section {
  margin-top: $spacing-07;
  h4 {
    text-align: center;
    @include type-style('body-long-02');
    color: $text-02;
  }
}

// Divider for Google login
.divider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  margin: 20px 0;
  
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    border-bottom: 1px solid #000;
  }
  
  h4 {
    display: inline-block;
    margin: 0 20px;
    padding: 0 10px;
    position: relative;
    background-color: transparent;
    z-index: 1;
  }
}

// Global button container styles
.button-container {
  display: flex;
  justify-content: center;
  margin: 7px;
}
.button-container > * {
  width: 258px !important;
  max-width: 100% !important;
}

// Social login container – merged styles from multiple definitions
.social-style-login {
  display: block;  // override the earlier flex declaration
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  
  .social-surround {
    padding: 10px;
  }
  
  // Remove border and shadow from buttons
  button.amazon-login-button,
  button.facebook-login-button,
  .social-style-login-button {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: auto;
  }
}

// Social button row – merged two definitions
.social-button-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 900px; // Adjusted for spacing
  margin: 10px auto;
  gap: 15px;
  
  .button-container {
    flex: 0 0 auto;
    width: 258px;      // explicit width matching button size
    margin: 5px;
    box-sizing: border-box;
    
    // Adjust buttons inside social container
    .social-style-login-button,
    .fb-button {
      width: 100% !important;
      max-width: 258px;
      margin: 0 auto;
    }
  }
}

// Specific social buttons
.microsoft-login-button,
.apple-login-button {
  width: 258px !important;
  justify-content: center;
}

// Facebook button – merged two definitions
.fb-button {
  height: 38px !important;
  width: 258px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 10px !important; // using the latter padding
  box-sizing: border-box !important;
  
  span {
    padding-top: 2px !important;
    font-size: 14px !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fa-facebook {
  font-size: 18px !important;
  margin-right: 8px !important;
}
