@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
@use './mixins.scss' as *;
@use './overrides.scss';


button.sb-tooltip-trigger {
  border: 0;
}

.llm-submit-button {
  margin-top: 10px; // Keeps the existing space above the button
  margin-bottom: 20px; // Increases space below the button
  font-size: 0.9em; // Keeps the smaller text size
  padding: 4px 12px; // Reduces vertical padding to decrease button height
}

.llm-request-textarea {
  width: 100%;
  min-height: 90px;
  font-size: 16px;
  // white-space: pre-wrap; (uncomment if needed)
}

div.llm-response-area {
  padding-top: 30px;
}

.llm-response-area {

  h2, h3 {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 1.5em; 
  }

  h2 {
    font-size: 1.25em; 
  }

  h3 {
    font-size: 1.15em; 
  }

  ul, ol {
    list-style-position: inside; // Keeps the marker inside the content flow
    padding-left: 0; // Removes default padding
    margin-left: 20px; // Adjust for indentation

    li {
      margin-bottom: 8px; // Space between list items
    }
  }

  ul {
    list-style-type: disc; // Bullet style
  }

  ol {
    list-style-type: decimal; // Numbering style
  }

  pre {
    background-color: #f5f5f5; /* Light gray background */
    border: 1px solid #ddd; /* Border around the code block */
    border-radius: 4px; /* Rounded corners */
    padding: 15px; /* Padding inside the code block */
    overflow-x: auto; /* Horizontal scrolling for long lines */
    font-family: 'Courier New', Courier, monospace; /* Monospace font for code */
  }
  
  code {
    color: #333; /* Darker text color for contrast */
    font-family: 'Courier New', Courier, monospace; /* Monospace font */
  }
  
  /* Optional: Styling for specific code languages (e.g., Python) */
  code.language-python {
    /* Custom styles for Python code */
  }
  
  
}

.landing-page {
  &__illo {
    max-width: 100%;
  }

  &__banner, &__r2, &__r3 {
    padding-top: $spacing-06;
    padding-bottom: $spacing-06;
    padding-left: $spacing-06;
    margin-left: -20px;
    margin-right: -20px;

    @include landing-page-background;

    @include breakpoint-down(md) {
      margin-left: 0;
      margin-right: 0;
    }
  }


  &__banner {
    padding-bottom: 1px;
    padding-top: 1px;
  }


  &__heading {
    @include type-style('productive-heading-05');
    margin-bottom: 1px; // Reduced bottom margin for the heading
  }

  &__r2 {
    margin-top: rem(-40px);
  }

  &__subheading, &__p {
    @include type-style('productive-heading-03');
    margin-top: $spacing-06;
    margin-bottom: $spacing-08;
  }

  &__subheading {
    font-weight: 600;
    margin-top: 1px; // Reduced top margin for the subheading
  }

  &__p {
    @include breakpoint-between((320px + 1), md) {
      max-width: 95%;
    }
  }

  &__label {
    @include type-style('heading-01');
  }

}

div.accordion-top-space {
  margin-top: 1.5em;
  margin-bottom: 2em;
}

div.tabs-group-content {
  padding: 1rem; //This is padding around
}

.login-button {
  margin-bottom: 1em;
}

.llm-select {
  margin-bottom: 1em;
  font-size: 16px;
}

.llm-submit {
  margin-top: 1em;
}

.tabs-group {
  background-color: $layer-01;

  &-content {
    padding: $spacing-10 $spacing-06;
  }
}

.use-tools-container {
  display: flex;
  flex-direction: row;
  align-items: baseline; /* Align items based on text baseline */
  flex-wrap: wrap;
  gap: 8px;
}

.tools-select {
  width: auto;
  min-width: 350px;
  max-width: 390px;
  flex-shrink: 0;
  margin-top: -28px; /* Adjust this value to align vertically */
  /* Or use align-self */
  align-self: center;
  margin-bottom: 10px;
}

.sb-tooltip-trigger {
  margin-left: 4px;
}

.use-tools-container input,
.use-tools-container label,
.use-tools-container .bx--list-box__field {
  line-height: 1.5; /* Ensure consistent line-height */
}
