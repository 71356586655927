@use '@carbon/react/scss/colors';

.s3-integration-page {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.s3-integration-container {
  min-width: 320px;
  max-width: 720px;
  width: 100%;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  h1 {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;

    > .bx--btn {
      flex: 1 1 auto;
    }
  }

  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;

    label {
      font-size: 0.875rem;
    }
  }

  // Increase margin to ensure dropdown doesn't overlap buttons
  .multi-select {
    margin-bottom: 4rem; // Increased from 1.5rem to 4rem
  }

  .error-message {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
}
