@use '@carbon/react';
@use '@carbon/react/scss/breakpoint' as *;
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cds--content {
  margin-top: 3rem;
}

.cds--content {
  margin-top: 3rem;
  padding: 0;
  background: var(--cds-background);
}

.cds--content .cds--css-grid {
  max-width: 100%;
  @include breakpoint(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
}